.appointmentForm{
  max-width: 50vw !important;
  padding: 20px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.appointmentMain {
  margin: auto;
  width: 50%;
  gap: 5px;
  /* background-color: blue; */
}

.appointment-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* background-color: aqua; */
}

.appointment-form-group label {
  padding-bottom: 2px;
  color: #3c6809;
}

.appointment-form-group input {
  width: 50%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}

.appointment-form-group select, .appointment-form-group .searchInputDropdown {
  width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}


.dropdown-list{
  min-width: 300px;
  width: auto !important;
  height: auto;
  padding: 10px 30px 20px 30px;
  margin-top: 2px;
  /* position: absolute; */
  border-radius: 30px;
  color: #3c6809;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  /* display: none; */
}
.appointment-form-group textarea {
  max-width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}

#scheduling_date, #appointment_date, #appointment_time {
  width: 200px;
}
.appointment-form-group input:focus {}

.appointment-form-group input.error, .appointment-form-group select.error {
  border: 1px solid red;
}

.appointment-form-group #birth_date, .appointment-form-group #since_date {
  width: 110px;
  color: #505050;
}

.trSelects {
  display: flex;
  gap: 30px;
  padding: 5px;
  /* background-color: purple; */
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}

.formButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.sendBtn {
  height: 25px;
  margin-top: 25px;
}


@media (max-width: 1150px) {}
@media (max-width: 768px) {}
@media (max-width: 430px) {}

