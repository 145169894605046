.anthropometryForm {
  min-width: 95%;
  min-height: 100vh;
  padding: 10px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.anthropometryMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 15px;
  /* background-color: blue; */
}
.anthropometry-colA {
  min-width: 25%;
  padding: 20px;
  margin: 0px;
  /* background-color: red; */
}
.anthropometry-colB {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  gap: 10px;
  min-width: 40%;
  max-width: 40%;
  align-items: center;
  /* background-color: yellow; */
}
.anthropometry-colC {
  display: flex;
  margin-top: 20%;
  min-width: 20%;
  max-width: 20%;
  padding: 20px;
}


.anthropometry-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* background-color: aqua; */
}

.anthropometry-form-group label {
  padding-bottom: 2px;
  color: #3c6809;
}

.anthropometry-form-group input {
  width: 50%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}

.anthropometry-form-group select, .anthropometry-form-group .searchInputDropdown {
  width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}


.dropdown-list{
  min-width: 300px;
  width: auto !important;
  height: auto;
  padding: 10px 30px 20px 30px;
  margin-top: 2px;
  /* position: absolute; */
  border-radius: 30px;
  color: #3c6809;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  /* display: none; */
}
.anthropometry-form-group textarea {
  max-width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}

#scheduling_date, #anthropometry_date, #anthropometry_time {
  width: 200px;
}
.anthropometry-form-group input:focus {}

.anthropometry-form-group input.error, .anthropometry-form-group select.error {
  border: 1px solid red;
}

.anthropometry-form-group #birth_date, .anthropometry-form-group #since_date {
  width: 110px;
  color: #505050;
}

.trSelects {
  display: flex;
  gap: 30px;
  padding: 5px;
  /* background-color: purple; */
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}

.formButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.sendBtn {
  height: 25px;
  margin-top: 25px;
}
.anthropometry-colB .accordion {
  width: 280px;
  height: 25px auto;
  gap: 5px;
  background: none!important;
}

.anthropometry-colB .accordion-header {
  height: 25px;
  font-size: 0.8rem;
  color: #3c6809;
  /* border: 1px solid #3c6809; */
  background: #5b960f3c;
}
.anthropometry-colB .accordion-header span{
  font-size: 0.9rem;
  width: 17px;
  height: 17px;
}
.anthropometry-colB .accordion-content {
  width: 80%;
}
.anthropometry-colB .accordion-content {
  width: 250px;
  height: auto !important;
  font-size: 0.8rem;
  color: #3c6809;
  border-left: 1px solid #3c6809;
  border-right: 1px solid #3c6809;
  border-bottom: 1px solid #3c6809;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
/* Estilos adicionais para quando o conteúdo estiver visível */
.anthropometry-colB .accordion.open .accordion-header {
  border: none !important;
  background-color: #8bc540!important;
  color: aliceblue;
}
.anthropometry-colB .accordion.open .accordion-content {
  display: block;
  padding: 20px 10px 10px 10px;
  background-color: #f5f5f5;
  margin-left: 4px;
}

.anthropometry-colB .accordion.closed .accordion-content {
  display: none;
}

@media (max-width: 1150px) {}
@media (max-width: 768px) {}
@media (max-width: 430px) {}

