.sign-container{
  width: 98%;
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0px auto;
  margin-top: 40px;
  border-radius: 5px;
  background-color: #8bc5404d !important;
}

.signContainer span{
  margin: 10px 0px 20px 0px;
  color: aliceblue;
  font-size: 0.8rem;
  font-weight: normal;
  font-family:Arial, Helvetica, sans-serif;
}

.signBox{
  margin: 0px auto;
  width: 230px;
  min-height: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 3px solid #69ff8e54;
  background-color: #8bc54018 !important;
}

.signBox .form-label{
  width: 100%;
  text-align: left;
  color: rgb(235, 228, 226);
  font-size: 0.8rem;
}
.signBox input{
  width: 200px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #d9d8d854;
}
.loginBtn {
  display: flex;
  justify-content:center ;
}


.sign-container .messages{
  font-weight: normal;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 20px;
}

.exitLink a{
 padding: 10px !important; 
 margin-top: -40px;
 font-size: 0.8rem;
 color: aliceblue !important;
}


.signVideoBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.signVideoBox video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}




