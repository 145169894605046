.boxGetCompany{
  max-width: 95%;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getCompanyTable {
  min-width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-size: 0.7rem;
  color: #3c6809;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getCompanyTable thead tr{
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #8bc54095;
}
.getCompanyTable tbody tr{
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.616);
  margin-top: 3px;
  }
.getCompanyTable tbody tr:hover{
    background-color: rgb(255, 255, 255);
}

.getCompanyTable tr, td {
  padding: 3px;
  color: #115208;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
}
.getCompanyTable tbody tr td {
  text-align: left;
   color: #3c6809;
}

/* Definindo largura mínima para as colunas */
.getCompanyTable td:nth-child(1), .getCompanyTable th:nth-child(1) {
  min-width: 5%;
  max-width: 5%;
}
.getCompanyTable td:nth-child(2), .getCompanyTable th:nth-child(2) {
  min-width:20%;
  max-width:20%;
}
.getCompanyTable td:nth-child(3), .getCompanyTable th:nth-child(3) {
  min-width:15%;
  max-width:15%;
}
.getCompanyTable td:nth-child(4), .getCompanyTable th:nth-child(4) {
  min-width:20%;
  max-width:20%;
}
.getCompanyTable td:nth-child(5), .getCompanyTable th:nth-child(5) {
  min-width:10%;
  max-width:10%;
}
.getCompanyTable td:nth-child(6), .getCompanyTable th:nth-child(6) {
  min-width:10%;
  max-width:10%;
}
.getCompanyTable td:nth-child(7), .getCompanyTable th:nth-child(7) {
  min-width: 6%;
  max-width: 6%;
}
.getCompanyTable td:nth-child(8), .getCompanyTable th:nth-child(8) {
  min-width: 6%;
  max-width: 6%;
}

.getCompanyTable button.icoBtn {
  width: 20px;
  height: 20px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  text-align: center;
  padding: 2px;
  background-color: #8bc540c7;
}

.getCompanyTable button.icoBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color: aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getCompanyTable button.icoBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getCompanyTable button.tdBtn {
  width: auto; /* Ajuste automático da largura */
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
  font-size: 0.8rem;
}
.getCompanyTable button.tdBtn:hover {
  font-weight: normal;
}
.getCompanyTable button.tdBtn img {
  width: 30px;
  height: 30px;
  border: 2px solid #8bc540;
  border-radius: 50px;
  background-color: transparent !important;
  cursor: pointer;
  object-fit: cover;
}

.getCompanyTable button.tdBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 160px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color: aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getCompanyTable button.tdBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getCompanyTable .pagination button {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border: none;
  border-radius: 2px !important;
  margin-left: 5px;
  text-align: center;
  padding: 0px;
  color: aliceblue;
  background-color: #8bc54062;
}

.getCompanyTable .pagination button.active {
  color: aliceblue;
  background-color: #8bc540c7;
}

.getCompanyTable .searchBtn {
  color: aliceblue;
  background-color: #5b960fb6;
}

.getCompanyTable .searchBtn:hover {
  color: #5b960fb6;
  background-color: #2c460b62;
  cursor: pointer;
}

.getCompanyTable .rowMessage {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bolder;
  width: 100%;
   color: #3c6809;
  /* background-color: #65327542; */
}
 
.col-md-12 .buttonsSearch {
  display: flex;
}
.col-md-12 .addCompanyBtn {
  width: 150px;
  height: 30px;
  border-radius: 50px;
  color: aliceblue;
  background-color: rgb(48, 129, 235);
  cursor: pointer;
}