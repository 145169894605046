.dashboard{
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.cards-menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 80px;
  /* background-color: yellow; */
}

.groupBtnMobile, .mobile-label{
  display: none;
}

.groupBtn{
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 2px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgb(255, 255, 255);
}
.groupBtn-G{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  height: auto;
  padding: 12px 0px 12px 20px;
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: normal;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
  color: #3c6809;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgb(255, 255, 255);
}
.shortcutBtn{
  text-align: center;
  align-self: center;
  font-size: 0.5rem;
}
.groupBtn img {
  width: 35px;
  height: 35px;
}
.groupBtn:hover {
  transform: scale(1.2);
}
.groupBtn:active {
  transform: translateY(5px);
}
.label{
  margin-top: 12px;
  width: 160px;
  height: 60px;
  text-align: center;
  font-size: 0.6rem;
}
.group-menu a:hover{
  text-decoration: none;
  color: #087766;
  font-weight: normal;
}
@media (max-width: 930px) {
 
}

@media (max-width: 768px) {
 
} 

@media (max-width: 430px) {

}