
.rbstyle{
  display: flex;
width: 100%;
justify-content: flex-start;
/* background-color: rgba(255, 0, 0, 0.404); */
}
.rbstyle input[type="radio"]{
display: none;
}
.rbstyle label{
position: relative;
font-size: 0.7rem;
/* border: 2px solid #8bc540; */
border-radius: 50px;
padding: 5px 10px;
display: flex;
align-items: center;

}
.rbstyle label:before{
/* position: absolute; */
content: "";
height: 8px;
width: 8px;
border: 1px solid #055027;
border-radius: 50%;
margin-right: 20px;
}
.rbstyle input[type="radio"]:checked + label{
border: 1px solid #055027;
background-color: #fff;
}
.rbstyle input[type="radio"]:checked + label::before{

background-color: #8bc540;
}