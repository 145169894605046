.calendarDashboard {
  width: 55% auto;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3c6809;
}

.calendarInfo{
 margin-bottom: 30px;
}

div.rbc-calendar {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  font-size: 0.7rem;
  background-color: rgb(255, 255, 255);
}
div.rbc-toolbar {
  font-size: 0.8rem;
}
div.rbc-toolbar button, div.rbc-header span {
  font-size: 0.6rem;
}
div.rbc-agenda-view span, div.rbc-row span, .rbc-label{
  color: #3c6809;
}
div .rbc-events-container{
  background-color: #8bc5402d;
  }
div .rbc-event {
  background-color: #8bc540d3;
}
div .rbc-selected::after {
  background-color: #8bc540d3;
}

div .rbc-toolbar-label{
  color: #3c6809;
}

.rbc-btn-group {
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687) !important;
}

.rbc-btn-group button {
  /* background-color: #8bc54080 !important; */
  border: 2px solid #8bc54080 !important;
}
.rbc-btn-group button.rbc-active {
  background-color: #8bc540D3 !important;
  border: 2px solid #8bc540 !important;
}
.rbc-btn-group button:hover {
  background-color: #8bc540D3 !important;
  border: 2px solid #8bc54080 !important;
}
div.rbc-event.rbc-selected{
  background-color: #5b960fb6 !important;
}
.rbc-agenda-time-cell span{
font-size: 0.8rem;
}
span.rbc-toolbar-label, span.rbc-label {
  font-size: 0.8rem;
}
.rbc-time-slot {
  font-size: 0.7rem;
}
div.rbc-data-cell span {
  color: red;
}
button.rbc-button-link, .rbc-show-more{
  font-size: 0.7rem;
}
button.rbc-show-more{
  font-size: 0.6rem;
}

div.rbc-day-bg, div.rbc-row-bg, div.rbc-month-row {
 font-size: 0.7rem;
}
div.rbc-day-bg.rbc-today{
  /* background-color: #5b960fb6!important; */
  border: 2px solid #5b960fb6!important;
} 