.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Adicione um fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11000; /* Ajuste conforme necessário */
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%; /* Ajuste conforme necessário */
  max-width: 600px; /* Ajuste conforme necessário */
  max-height: 80vh; /* Ajuste conforme necessário */
  overflow-y: auto;
  box-shadow: 10px 15px 38px 5px #8bc540;
  color: #3c6809;
  background-color: #ffffffb9;
}
.modalButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.modalBtn{
  width: 100px;
  height: 30px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50px;
  border: none;
  color: aliceblue;
  background-color: #679e15a9;
}
.modalBtnCancel{
  width: 100px;
  height: 30px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50px;
  border: none;
  color: aliceblue;
  background-color: #ba0a0aa6;
}
.modalBtn:hover{
  font-weight: bold;
  color: #2c550a98;
  border: 1px solid  #2c550a98;
  background-color: #93ff07;
  cursor: pointer;
}
.modalBtnCancel:hover {
  color: #ffffff;
  border: 1px solid  #ffffff;
  background-color: #f01818ee;
  cursor: pointer;
}