.dietForm{
  max-width: 90vw !important;
  padding: 20px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.dietMain {
  margin: auto;
  width: 90%;
  gap: 5px;
  margin-top: 20px;
  /* background-color: blue; */
}
.dietLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
.dietLine.editDiet{
  width: 97.5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #8bc540;
  padding: 10px 0px 10px 20px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}
.dietLine.editDiet select, .dietLine.editDiet input{
  width: 300px;
}
.dietLine.editDiet .diet_total_calories{
  width: 100px;
}
.diet-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* background-color: aqua; */
}

.diet-form-group label {
  padding-bottom: 2px;
  color: #3c6809;
}

.diet-form-group input, .diet-form-group select {
  width: 30%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}

.diet-form-group textarea {
  max-width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}

#scheduling_date, #diet_date, #diet_time {
  width: 150px;
}

.diet-form-group input.error, .diet-form-group select.error {
  border: 1px solid red;
}

.diet-form-group #birth_date, .diet-form-group #since_date {
  width: 110px;
  color: #505050;
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}

.formButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.dietButtons .sendBtn {
  min-width: 200px;
  width: auto;
  height: 25px;
  margin-top: 25px;
  padding: 0px 12px 0px 12px;
}
.dietButtons .backBtn {
  min-width: 200px;
  width: auto;
  height: 25px;
  margin-top: 25px;
  padding: 0px 12px 0px 12px;
}
.dietButtons .sendBtnLarge{
  width: 22%;
  min-height: 30px;
  height: auto;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  color: aliceblue;
  font-size: 100%;
  background-color: #5b960fb6;
}
button.addMeal {
  min-width: 150px;
  width: auto;  
  min-height: 30px;
  height: auto;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  color: aliceblue;
  font-size: 100%;
  background-color: #5b960fb6;
}
.mealButtonLine{
  padding: 0px 0px 10px 0px;
}
button.removeMeal {
  min-width: 150px;
   width: auto;
    height: 25px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(145, 7, 7, 0.687);
    color: aliceblue;
    background-color: #c54040a8;
    margin-bottom: 30px;
}
.dietButtons{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.btnIcoAddRm.iconPlus {
  width: 20px;
  height: 20px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  text-align: center;
  padding: 2px;
  background-color: #406fc5c7;
} 

.btnIcoAddRm.iconPlus::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.btnIcoAddRm.iconPlus:hover::after {
  visibility: visible;
  opacity: 1;
}


.btnIcoAddRm {
  background-color: transparent !important;
  border: none !important;
  color: #3c6809;
  cursor: pointer;
}

.meal-group{
  background-color: yellow;
  background-color: rgba(253, 253, 250, 0.336);  
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
  border: 1px solid #8bc540;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  margin-bottom: 10px;
}
.meal-group .dropdown-list{
  min-width: 95%;
  width: auto !important;
  height: auto;
  /* padding: 10px 30px 20px 30px; */
  padding: 10px 10px 10px 3px;
  margin-top: 2px;
  /* position: absolute; */
  border-radius: 30px;
  color: #3c6809;
  background-color: #ffffff00;
  border: 1px solid #ccc;
  border: none !important;
  z-index: 10;
  /* display: none; */
}

#time-meal {
  width: 80px !important;
}
#objectiveField{
  width: 50% !important;
  min-width: auto !important;
}
.dietForm ul{
  text-decoration: none;
  list-style: none;
}
ul .diet-form-group, ul .foodListField {
  max-width: 100px !important;
}
ul .diet-form-group.foodName{
min-width: 400px !important;
width: auto !important;
}

@media (max-width: 1150px) {}
@media (max-width: 768px) {}
@media (max-width: 430px) {}

