.companyMain {
  display: flex;
  gap: 15px;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.colA {
  min-width: 10%;
  /* background-color: red; */
}

.colB {
  min-width: 70%;
  background-color: rgba(255, 255, 255, 0.616);
}

.colC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 200px auto;
  border-radius: 10px;
  padding: 5px;
  margin-top: 25vh;
  font-size: 0.7rem;
  text-align: center;
  background-color: #8bc54038;
}

.company-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* background-color: aqua; */
}

.company-form-group label {
  padding-bottom: 2px;
  color: #808080;
}

.company-form-group input {
  width: 95%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #505050;
  border: 1px solid #8bc540;
}

.company-form-group select {
  width: 95.5%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #505050;
  border: 1px solid #8bc540;
}
.company-form-group textarea {
  max-width: 95%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}
#uf, #zipcode{
  width: 200px;
}
.company-form-group input:focus {}

.company-form-group input.error, .company-form-group select.error {
  border: 1px solid red;
}

.company-form-group #birth_date, .company-form-group #since_date {
  width: 110px;
  color: #505050;
}


.pfgCol1, .pfgCol2, .pfgCol3 {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.trSelects {
  display: flex;
  gap: 30px;
  padding: 5px;
  /* background-color: purple; */
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}


.sendBtn {
  height: 25px;
  margin-top: 25px;
}


.tabs {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-bottom: 5px;
  height: 30px;
/* background-color: greenyellow; */
}

.tabs li {
  padding: 2px 15px;
  cursor: pointer;
  border: 1px solid #ccc;
  color: #8bc540;
  border-radius:100px;
  margin-left: 5px;
  background-color: #8bc54038;
}

.tabs li.active {
  color: #8bc540;
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}

@media (max-width: 1150px) {
  .companyMain {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

}

@media (max-width: 768px) {}

@media (max-width: 430px) {

  .colC{
    width: 300px;
    margin-top: 2px;
  }
}
