.getConsultationsMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 15px;
  /* background-color: blue; */
}

.getConsultationsMain ul, .getConsultationsMain a{
  text-decoration: none;
  list-style-type: none;
}
.row-Consultations {
  display: flex;
  flex-wrap: wrap!important;
  justify-content: space-between!important;
  width: 95% !important;
  padding: 10px;
  gap: 25px;
  border: 1px solid #3c6809;
  border-radius: 15px;
  background: radial-gradient(circle, rgba(245, 245, 245, 0) 0%, #f5f5f5 100%);
  margin-bottom: 15px;
  /* background-color: yellow; */
}
.row-Consultations .row-box {
display: flex;
justify-content: space-between;
flex-wrap: wrap;
gap: 3px;
padding: 5px;
margin: -30px 0px 0px 0px;
/* background-color: blue; */
}
.row-Consultations .row-line{
  width: 190px;
  padding: 5px;
  font-weight: bold;
  /* background-color: red; */
}

/* Container da linha */
.row-Consultations .titleLine {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 70px;
  overflow: hidden; /* Esconde a imagem ao sair da área visível */
}



/* Container para a imagem */
.sliding-image-container {
  width: 70px;
  height: 50px;

}

/* Estilo da imagem */
.sliding-image-container img {
  width: 100%;
  height: 100%;
  transform: translateX(100%) scaleX(1); /* Inicialmente fora da tela, à direita */
  animation: slide-horizontal 4s infinite alternate ease-in-out; /* Animação contínua */
}

/* Animação de deslizamento */
@keyframes slide-horizontal {
  0% {
    transform: translateX(-100%) scaleX(-1); /* Começa fora da tela, à esquerda */
  }
  
  100% {
    transform: translateX(100%) scaleX(-1); /* Termina fora da tela, à direita */
  }
}

.row-Consultations label{
  color: #3c6809;
  font-size: 0.8rem;
  font-weight: normal;
}
.row-getConsultations {
  display: flex;
  flex-wrap: wrap!important;
  justify-content: space-between!important;
  width: 98% !important;
  padding: 10px;
  gap: 25px;
  border: 1px solid #3c6809;
  border-radius: 15px;
  justify-content: flex-start;
  margin: 10px 0px 10px 0px;
  /* background-color: green; */
}

.row-getConsultations .colAnt {
  font-weight: bold;
}
.row-getConsultations .colAnt label {
  font-weight: normal;
}
.row-getMeasures {
  display: flex;
  flex-wrap: wrap!important;
  justify-content: space-between!important;
  width: 98% !important;
  padding: 10px;
  gap: 25px;
  border-radius: 15px;
  justify-content: flex-start;
  margin: 10px 0px 10px 0px;
  /* background-color: green; */
}
.row-getConsultations .colAnt {
    width: 20%;
    background-color: #aafd4a86;
    border-radius: 10px;
    padding: 10px;
   /* background-color: red; */
}
.row-Consultations .colEmpty {
    width: 20%;
    border-radius: 50px;
    padding: 10px;
   /* background-color: red; */
}
.getConsultations-form-group {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 0px;
  /* background-color: aqua; */
}

.getConsultations-form-group label {
  padding-bottom: 2px;
  color: #3c6809;
}


.getConsultations-form-group .custom-select__control {
  width: 500px !important;
  cursor: pointer!important;
}

.Consultations-form-group .dropdown-list {
  min-width: 500px!important;
  width: auto !important;
  height: auto;
  padding: 10px 30px 20px 30px;
  margin-top: 2px;
  /* position: absolute; */
  border-radius: 30px;
  color: #3c6809;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  /* display: none; */
}
.getConsultations-form-group textarea {
  max-width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}

#scheduling_date, #anthropometry_date, #anthropometry_time {
  width: 200px;
}
.getConsultations-form-group input:focus {}

.getConsultations-form-group input.error, .getConsultations-form-group select.error {
  border: 1px solid red;
}

.getConsultations-form-group #birth_date, .getConsultations-form-group #since_date {
  width: 110px;
  color: #505050;
}

.trSelects {
  display: flex;
  gap: 30px;
  padding: 5px;
  /* background-color: purple; */
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}

.formButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.sendBtn {
  height: 25px;
  margin-top: 25px;
}
.consultationTitle{
  font-size: 1rem;
  color: #3c6809;
  padding-left: 15px;
}

/* Accordion */
.getConsultationsMain .accordion {
  width: 80vw!important;
  height: 25px auto;
  gap: 5px;
  background: none!important;
  padding: 0px!important;
  /* background-color: red!important; */
}

.getConsultationsMain .accordion-header {
  width: 100%!important;
  height: 25px;
  font-size: 0.8rem;
  color: #3c6809;
  /* border: 1px solid #3c6809; */
  background: #5b960f3c;
}
.getConsultationsMain .accordion-header span{
  font-size: 0.9rem;
  width: 17px;
  height: 17px;
}

.getConsultationsMain .accordion-content {
  width: 100%!important;
  display: flex;
  height: auto !important;
  font-size: 0.8rem;
  color: #3c6809;
  border-left: 1px solid #3c6809;
  border-right: 1px solid #3c6809;
  border-bottom: 1px solid #3c6809;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* Estilos adicionais para quando o conteúdo estiver visível */
.getConsultationsMain .accordion.open .accordion-header {
  border: none !important;
  background-color: #8bc540!important;
  color: aliceblue;
}
.getConsultationsMain .accordion.open .accordion-content {
  display: block;
  width: 100%!important;
  padding: 20px 10px 10px 10px;
  background-color: #f5f5f5;
  margin-left: 4px;
}

.getConsultationsMain .accordion.closed .accordion-content {
  display: none;
}


/* GetAccordion */
.getAccordion {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  width: 100%;
  height: auto;
  gap: 15px;
  /* background-color: blue; */
}
.getAccordion .accordion {
  width: 85vw!important;
  height: 25px auto!important;
  gap: 5px;
  background: none!important;
  padding: 0px!important;
  /* background-color: red!important; */
}
.getAccordion .accordion-header {
  width: 95%!important;
  height: 25px;
  font-size: 0.7rem;
  color: #3c6809;
  /* border: 1px solid #3c6809; */
  background: #8bc540;
  background: none;
  padding: 1px 12px 1px 12px;
  border-radius: 5px !important;
}

.getAccordion .accordion-header span{
  font-size: 0.7rem !important;
  width: 17px;
  height: 17px;
}

.getAccordion .accordion-content {
  width: 95%!important;
  display: flex;
  flex-direction: column;
  height: auto !important;
  font-size: 0.8rem;
  color: #3c6809;
  /* border-left: 1px solid #3c6809;
  border-right: 1px solid #3c6809;
  border-bottom: 1px solid #3c6809;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
}

.getAccordion .accordion-content .accordion {
  width: 80vw!important;
  height: 35px auto!important;
  gap: 5px;
  background: none!important;
  padding: 0px!important;
  margin: 0px !important;
  /* background-color: red!important; */
}

.getAccordion .accordion-content .accordion-header{
  border-radius: 0px !important;
  border-bottom: 1px solid #60aa6a;
}

/* Estilos adicionais para quando o conteúdo estiver visível */
.getAccordion .accordion.open .accordion-header {
  border: none !important;
  background-color: none !important;
  background: none !important;
  color: aliceblue;
  margin-bottom: 25px;
}

.getAccordion .accordion.open .accordion-content .accordion-header {
  border: none;
  border-bottom: 1px solid #ffffff31 !important;
  background-color: #8bc540c0 !important;
  margin-bottom: 2px;
  color: #3c6809;
  border-left: 1px solid #3c6809 !important;
  border-right: 1px solid #3c6809 !important;
  border-bottom: 1px solid #3c6809 !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.getAccordion .accordion-content .accordion.open  .accordion-header {
  color: aliceblue !important;
}

.getAccordion .accordion.open .accordion-content {
  border: none;
  /* background: #f5f5f5; */
}
.getAccordion .accordion.open .accordion-content .colAnt{
  background-color: #f5f5f5c9;
}

.getAccordion .accordion.closed .accordion-content {
  display: none;

}

.getAccordion .pagination {
  margin-top: 30px !important;
}

.getAccordion .row-headAnt{
  display: flex;
  width: 75%;justify-content: space-between;
}

/* Get Anthropometry Box */
.row-getConsultationsBox {
  display: flex;
  width: 75vw;
  min-height: 50px;
  height: auto !important;
  gap: 5px;
  /* background-color: red !important; */
}

.row-getConsultationsBox .colAntBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  border-radius: 10px;
  gap: 2px;
  background-color: #8bc54093;
  font-size: 0.7rem;
}
.rowAntBox{
  font-size: 0.8rem;
}

.getConsultationsForm .returnDashboard {
  align-self: center !important;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin: 0;
  color: aliceblue;
  background-color: #6a9630;
  border: none !important;
}

.getConsultationsForm button.returnDashboard::after {
    content: attr(data-tip);
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: fixed;
    z-index: 2;
    top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
    left: 50%;
    margin-left: -60px;
    font-size: 0.8rem;
    color:aliceblue;
    background-color: #8bc540c7;
    opacity: 0;
    transition: opacity 0.6s;
  }
  
.getConsultationsForm button.returnDashboard:hover::after {
    visibility: visible;
    opacity: 1;
  }
  


@media (max-width: 1150px) {}
@media (max-width: 768px) {}
@media (max-width: 430px) {}

