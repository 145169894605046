.boxGetUser {
  max-width: 95%;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getUserTable {
  max-width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-size: 0.7rem;
  color: #3c6809;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getUserTable a{
  font-size: 0.7rem;
   color: #3c6809;
}
.getUserTable tr, td {
  padding: 3px;
  /* border-top: 1px solid #8bc540; */
  text-align: left;
   color: #3c6809;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.getUserTable thead tr {
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #8bc54095;
}
.getUserTable tbody tr{
justify-content: space-between;
padding: 0px 0px 0px 10px;
border-radius: 5px;
background-color: rgba(255, 255, 255, 0.616);
margin-top: 3px;
}
.getUserTable tbody tr:hover{
  background-color: rgb(255, 255, 255);
}


.getUserTable td:nth-child(1), .getUserTable th:nth-child(1) {
  width: 50% auto;
}
.getUserTable td:nth-child(2), .getUserTable th:nth-child(2) {
  width:34% auto;
}

.getUserTable td:nth-child(3), .getUserTable th:nth-child(3) {
  width: 8% auto;
  text-align: center;
}

.getUserTable td:nth-child(4), .getUserTable th:nth-child(4) {
  width: 8% auto;
  text-align: center;
}

.getUserTable button.icoBtn{
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  background-color: #8bc540c7;
} 

.getUserTable button.icoBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getUserTable button.icoBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getUserTable button.tdBtn {
  width: 220px;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.getUserTable button.tdBtn:hover{
 font-weight: bolder;
}

.getUserTable button.tdBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 160px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getUserTable button.tdBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.pagination button {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border: none;
  border-radius: 2px !important;
  margin-left: 5px;
  text-align: center;
  padding: 0px;
  color: aliceblue;
  background-color: #8bc54062;
}

.pagination button.active {
  color: aliceblue;
  background-color: #8bc540c7;
}

.searchBtn{
  color: aliceblue;
  background-color: #5b960fb6;
}

.searchBtn:hover{
  color: #5b960fb6;
  background-color: #5b960f23;
  cursor: pointer;
}

.rowMessage {
  text-align: center;
  font-size: 1rem;
  font-weight: bolder;
  width: 100%;
  color: #8bc540;
  /* background-color: #65327542; */
}

