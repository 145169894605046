@media (max-width: 930px) {
  .loginVideoBox .loginFallbackImg {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    object-fit: cover !important;
   }
   a {
    text-decoration: none !important;
   }
   .personMain .colC, .companyMain .colC {
    margin-top: 0px;
    width: auto !important;
    gap: 5px !important;
   }
   .personButtons, .companyButtons {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
    justify-content: center !important;
    align-items: center !important;
   }
   /* Company */
   .boxShowCompany a, .boxShowCompany.spanData{
    text-decoration: none;
   }
}

@media (max-width: 768px) {
  .loginVideoBox .loginFallbackImg {
    height: 100vh !important;
    object-fit: cover !important;
  }
  
  a {
    text-decoration: none !important;
  }
} 
/* ...................................................................................MOBILE */
@media (max-width: 431px) {
  body {
    background-color: rgb(255, 255, 255)!important;
    overflow-x: hidden; /* Evita movimento lateral na tela */
  }
  
  .mobileIcon {
    display: initial!important;
    padding-right: 10px;
  }
  
  a {
    text-decoration: none !important;
  }
  
  .avatarDefault {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #8bc540;
  }
  
  .login-container {
    flex-direction: column;
    background-color: #8bc54000;
    height: 80vh!important;
  }
  
  .login-container .title {
    width: 100%;
  }
  
  .loginBox {
    border: 3px solid #69ff8e54;
    background-color: #29360d60 !important;
  }
  
  .loginVideoBox video {
    position: fixed !important;
    height: 100vh !important;
  }
  
  .login-container .fallbackImg {
    width: 100%;
    height: 100vh !important;
    object-fit: cover !important;
  }
  
  .shortcutBtn img {
    width: 40px!important;
    height: 40px!important;
  }
  
  .shortcutBtn label {
    display: none !important;
  }

  /*..............................................................................Home Layout */
  .main-container {
    grid-template-areas:
        "navbar"
        "content"
        "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    width: 100vw; /* Ocupa toda a largura da tela */
    overflow-x: hidden; /* Evita movimento lateral */
    z-index: 4 !important; /* Garante que esteja acima da sidebar escondida */
  }
  
aside { 
    display: none !important; /* Remove completamente o aside */
    position: absolute; /* Remove o aside do fluxo do documento */
    top: 0;
    left: -9999px; /* Garante que esteja fora da tela */
    width: 0;
    height: 0;
    overflow: hidden; /* Garante que o conteúdo do aside também não interfira */
  }
  main .content {
    margin-top: 60px; /* Ajuste para a altura da navbar reduzida se necessário */
    margin-left: 0; /* Remove a margem lateral no mobile */
    padding: 0 10px -30px; /* Opcional: Adiciona padding lateral para um pouco de espaço na tela */
    z-index: 5; /* Garante que o conteúdo esteja acima de tudo */
    width: 100vw; /* Ocupa toda a largura da tela */
  }

  /* .........................................................Estilos adicionais para tabelas e formulários */
  .getUserTable, .getPersonTable, .getCompanyTable, .getAppointmentTable, .getConsultationTable {
    width: 100%!important;
    gap: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  
  .getUserTable tbody, .getPersonTable tbody, .getCompanyTable tbody, .getAppointmentTable tbody,
  .getUserTable td, .getPersonTable td, .getCompanyTable td, .getAppointmentTable td, .getConsultationTable td {
    max-width: 100% !important;
  }
  
  .getUserTable td, .getPersonTable td, .getCompanyTable td, .getAppointmentTable td, .getConsultationTable td  {
    border: none !important;
  }
  
  .getUserTable tr, .getPersonTable tr, .getCompanyTable tr, .getAppointmentTable tr, .getConsultationTable tr {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    border-top: none !important;
    border-bottom: 3px solid #8bc540;
    border-bottom-left-radius: 40px !important;
    border-bottom-right-radius: 40px!important;
    gap: 2px;
  }
  .getAppointmentTable tbody tr{
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
  }

  .getAppointmentTable tbody tr td, .getAppointmentTable tbody tr td a, .getConsultationTable tbody tr td, .getConsultationTable tbody tr td a {
    width: 100%;
    text-align: left !important;
    font-size: 0.8rem;
  }
  .getAppointmentTable tbody tr td.delete , .getConsultationTable tbody tr td.delete {
    width: 100%;
    display: flex !important;
    justify-content: center;
  }
  .getAppointmentTable tbody tr td.update, .getConsultationTable tbody tr td.update {
    width: 100%;
    display: flex !important;
    justify-content: center;
  }
  .getUserTable .tdBtn {
    font-size: 0.8rem;
    text-align: left;
    font-weight: normal;
  }
  
  .getUserTable thead,  .getPersonTable thead,  .getCompanyTable thead,  .getAppointmentTable thead, .getConsultationTable thead {
    display: none !important;
  }
  
  .companyMain, .personMain {
    width: 100%!important;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f2f2f2;
    padding: 10px;
    max-width: 89vw!important;
    margin-left: -10%; 
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  
  .personForm .tabs li, .companyForm .tabs li {
    font-size: 0.7rem;
    align-self: center;
  }
  
  .boxShowUser, .boxShowPerson, .boxShowCompany, .boxShowAppointment {
    min-width: 100% !important;
    border-radius: 10px;
    margin-left: -10% !important;
    z-index: 5!important;
  }
  .appointmentForm .appointmentMain {
    min-width: 65vw !important;
    margin-left: -2% !important;
    z-index: 6!important;
  }
  .appointmentForm, .userForm {
    min-width: 65vw !important;
    margin-left: -5% !important;
  }
  .boxGetAppointment .getAppointmentTable tbody{
    background-color: none !important;
    border: none !important;
  }
  .userSearchForm {
    flex-direction: column;  
    width: 100%;
    margin-left: -6%!important; 
  }
/* ....................................................Calendar */
.groupBtn-G{
  display: none!important;  
}

.cards-menu{
  display: flex;
  justify-content: space-around!important;
  width: 100%!important;
  position: relative!important;
  /* background-color: yellow!important; */
}
.groupBtn{
  display: none!important;
}
.groupBtnMobile {
  display: flex!important;
  flex-direction: column!important;
  align-items: center;
  justify-content: center!important;
  width: 80px!important;
  height: 80px!important;
  padding: 2px!important;
  border: 1px solid #8bc540!important;
  border-radius: 10px!important;
  cursor: pointer!important;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687)!important;
  background-color: rgb(255, 255, 255)!important;
}

.card-mobile{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column!important;
  gap: 5px;
}
.mobile-label{
  display: initial!important;
  color: #8bc540;
  font-size: 0.5rem;
  max-width: 30px;
  text-align: center;
}
.calendarDashboard{
  max-width: 50%!important;
  padding: 0px 5px 0px 5px ;

  margin-top: 40px !important;
}

  /* ............................SHOW TACO FOODS */
  .tacoFoodCard {
    height: auto!important;
    font-size: 0.7rem!important;
    margin-left: -20px!important;
  }
  .tacoFoodColumn1 .row1{
    flex-direction: column!important;
  }
  .tacoFoodColumn2 {
    display: none!important;
  }
  .tacoFoodColumn3 .boxLine{
    flex-direction: column!important;
  }

  .food-content{
    margin-left: -25px!important;
  }
  /* Navbar Mobile */

  .nav{
    justify-content: flex-start;
  }
  
  .navbar {
    width: 100%;
    height: 120px !important;
    display: flex;
    justify-content: center;
  }
  
  .navbarBrand {
    width: 120px;
    height: 120px!important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .imgLogo{
    margin-left: 5px !important;
    width: 60%;
    height: auto;
  }
  
  .navbarCenter{
    width: 55%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    gap: 1px;
  }
  
  .navbarRight{
    width: 20%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbarCenter{
    flex-direction: row;
    justify-content: center;
    gap: 10px!important;
  }
  
  .userInitials {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .initials{
    width: 25px!important;
    height: 25px!important;
    font-size: 0.6rem!important;
  }
  
  .btnLogout{
    width: 80px!important;
    height: 15px !important; 
    font-size: 0.8em;
    margin-right: 60px !important;
  }


  /* ANTHROPOMETRY ............................................... */
  .anthropometryMain {
    flex-direction: column !important;
    /* background-color: red !important; */
  } 
  .anthropometry-colB{
    margin-left: 80px !important;
  }
  .getAnthropometry-form-group {
    flex-direction: column !important;
  }
  .getAnthropometry-form-group .custom-select__control {
    max-width: 300px !important;
    margin: 0px !important;
    padding: 0px 0px 0px 10px !important;
    background-color: #fcfcfc!important;
    border: 1px solid #8bc540!important;
    min-height: 10px!important;
    font-size: 0.8rem!important;
    justify-content: flex-start;
  }
  .getAnthropometry-form-group .custom-select__input {
   width: 300px !important;
   /* background-color: red !important;   */
  }

  .getAccordion .accordion {
    max-width: 90vw !important;
    height: auto !important;
    gap: auto !important;
    min-height: 20vh !important;
    padding: 0px !important;
    margin: 20px 0px 0px -30px !important;
    /* background-color: red !important; */
  }

  .getAccordion .row-getMeasures {
    max-width: 80vw !important;
    height: auto !important;
    gap: auto !important;
    min-height: 20vh !important;
    /* background-color: red !important; */
  }

  .getAccordion .row-getAnthropometryBox {
    flex-direction: column !important;
    /* background-color: red !important; */
    margin-top: 50px !important;

  }

  .getAccordion .accordion.open .accordion {
    margin: 0px 0px -128px 0px !important;
    /* background-color: blue !important; */
 }
 .getAccordion .accordion.open .accordion-content:nth-last-child(1) {
  margin: 120px 0px 128px 0px !important;
  /* background-color: blue !important; */
}
.getAccordion .accordion.open .accordion .accordion-content {
  height: 100% !important;
  margin: 0px 0px 128px 0px !important;
  /* background-color: blue !important; */
}
.getAccordion .accordion.open .accordion .accordion-header  {
  margin-top: 0px !important;
}
.getAccordion .accordion.open .accordion .accordion-content .row-getAnthropometry{
  height: 100% !important;
  margin-bottom: auto!important;
  margin-top: auto!important;
  /* background-color: red !important; */
}

/* ....................................................................CONSULTAS........................................................... */
.boxShowConsultation {
  margin-left: -20px!important;
}
.boxShowConsultation .box-diet {
  width: 100% !important;
}
.boxShowConsultation .box-diet .box-diet-col-A{
  flex-direction: column;
}
.boxShowConsultation .box-diet .box-diet-col-B{
  flex-direction: column;
}
.boxShowConsultation .meal-card {
  width: 100%;
}
.boxShowConsultation .meal-line {
  flex-direction: column;
}
.boxShowConsultation .dietFoodLine {
  margin-left: -30px;
  width: 100%;
}
.consultationForm input[type="text"], .consultationForm input[type="textarea"] {
  min-width: 220px !important;
  width: auto;
  height: auto;
}
.consultationLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
.consultationLine .box-diet-col-A {
  width: 100%;
  flex-direction: column;
  /* background-color: red; */
}
.consultationButtons{
  flex-direction: column;
  margin-bottom: 40px;
}
.consultationButtons .backBtn{
  margin-top: -20px !important;
}
/* ...diets */
.dietForm {
  width: 100%;
  margin-left: -30px;
}
.dietForm input[type="text"], .consultationForm input[type="textarea"], select {
  min-width: 120px !important;
  width: auto;
  height: auto;
}
.dietForm .SelectField, .objectiveField {
  width: 230px !important;
}
.dietForm .fieldSelect {
  width: 200px !important;
}
.food-list .dietLine{
  margin-left: -40px;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
}
.dietForm .dietButtons{
  flex-direction: column;
  margin-bottom: 60px;
}
.dietForm .dietButtons .backBtn{
  margin-top: -5px;
  min-height: 25px !important;
}.dietForm .dietButtons .sendBtn{
  margin-top: 80px;
  min-height: 25px !important;
}

}
