/* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  margin: 0 5px;
  padding: 12px 12px;
  border: none;
  background-color: #8bc54061;
  color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #8bc540;
  margin: 0 15px;
}

.paginationSeparator {
  margin: 0 5px;
  color:  #8bc540;
}
