.boxShowPerson {
   line-height: 20px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  padding: 10px;
  font-weight: normal;
  background-color: rgba(255, 255, 255, 0.616);
}

.boxShowPerson h3 {
  margin: 2px 0px 0px 2px;
  font-size: 0.9rem;
}

.boxShowPerson .boxTop {
  /* background-color: rgb(22, 70, 54); */
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  gap: 10px;
}
.boxShowPerson .boxTopB .boxCol1{
  display: flex;
  flex-wrap: wrap;
}
.boxCol1 {
  display: flex !important;
  flex-wrap: wrap;
  line-height: 15px !important;
}
.box-line{
  display: flex !important;
  flex-wrap: wrap;
}
.boxShowPerson label {
  margin-right: 6px;
}
.boxShowPerson span {
  text-decoration: none;
  font-weight: normal;
  font-size: 0.8rem;
  color: #3c6809;
}
.boxShowPerson a, .boxCol1 a {
  text-decoration: none;
  font-weight: normal;
  font-size: 0.8rem;
  cursor: pointer;
  color: #3c6809;
}

.boxShowPerson .box-line {
  /* background-color: #f7fa59; */
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
  width: 100%;
}
.boxShowPerson .boxAppointment:hover {
  /* background-color: #f7fa59; */
  border: 2px solid #3c6809;
}

.boxShowPerson .boxCol1 {
  /* background-color: blue; */
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: auto;
  margin: 0 !important;
  padding: 0px 20px 0px 1px !important;
  line-height: 3px;
}

.boxAppointment {
  width: 150px auto;
  height: 150px auto;
  font-size: 0.7rem;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}

.boxButtons1, .boxButtons2, .boxButtons3, .boxButtons4, .boxButtons5 {
  display: flex;
  flex-direction: column;
  position: fixed; /* Fixa o painel na lateral da tela */
  right: 0; /* Alinha à direita */
  top: 40%; /* Posiciona no meio verticalmente */
  transform: translateY(-50%); /* Ajusta o posicionamento para que o meio dos botões fique no centro vertical */
}
.boxButtons2{
  margin-top: 40px;
}
.boxButtons3{
  margin-top: 80px;
}
.boxButtons4{
  margin-top: 120px;
}
.boxButtons5{
  margin-top: 160px;
}

.showBoxBtn {
  background-color: #8bc54069;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  border: none;
  border-radius: 5px;
  color: aliceblue;
  width: 60px; /* Largura inicial para mostrar apenas ícones */
  overflow: hidden; /* Esconde o texto inicialmente */
  white-space: nowrap; /* Mantém o texto na mesma linha */
  transition: width 0.3s ease; /* Suaviza a transição */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Impede o botão de encolher */
}

.showBoxBtn:hover {
  width: 120px; /* Largura maior ao passar o mouse para mostrar o texto */
  cursor: pointer;
}

.showBoxBtn .sideBtnSpan {
  margin-left: 10px; /* Espaçamento entre ícone e texto */
  font-size: 0.8rem; /* Tamanho do texto */
  display: none; /* Esconde o texto até hover */
}

.showBoxBtn:hover .sideBtnSpan {
  display: inline; /* Mostra o texto no hover */
  color: #365c05;
}

.sideIcoBtn {
  font-size: 20px; /* Tamanho do ícone */
  color: #365c05;
}
span a::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px auto;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

span a:hover::after {
  visibility: visible;
  opacity: 1;
}