.tacoFoodCard {
  width: 90%;
  height: 250px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  background-color: #ffffff75;
  font-size: 0.8rem;
  color: #3c6809;
}
.tacoFoodCard span{
  color: #3c6809;
  font-size: 1rem;
  font-weight: normal;
}
.tacoFoodColumn1,
.tacoFoodColumn2,
.tacoFoodColumn3 {
  flex: 1;
  padding: 5px;
  /* border: 2px solid #988999; */
}

.tacoFoodColumn1 .row1 {
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
  /* background-color: red; */
}
.tacoFoodColumn1 .row1 img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border: 1px solid #8bc540;
  justify-content: center;
  background-color: #4caf50;
}
.tacoFoodColumn1 .row1 .taco-colA, .tacoFoodColumn1 .row1 .taco-colB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.tacoFoodColumn1 .row1 .taco-colB {
  background-color: none !important;
}
.tacoFoodColumn1 .taco-colB .lbl-fruit-name {
  font-size: 1rem;
  font-weight: bold;
}
.tacoFoodColumn1 .taco-colB .lbl-category-food {
  font-size: 0.9rem;
  color: #8bc540;
}
.r3-describe{
  font-size: 0.8rem;
}
.tacoFoodColumn1 .row2{
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.tacoFoodColumn1 .row2 .tag-total{
  font-size: 1.5rem;
}
.tacoFoodColumn1 .row2 .tag-kcal{
  font-size: 2rem;
  font-weight: bolder;
}
.tacoFoodColumn1 .row2 .tag-calories{
  font-size: 1rem;
}
.tacoFoodColumn1 .row3 {
  display: flex;
  height: 30px;
  font-size: 0.9rem;
  padding-top: 35px;
}
.tacoFoodColumn2 {
 
}
.boxLine{
  display: flex;
}
.boxLabel{
  display: flex;
  align-items: center;
  width: 100px;
  height: 25px;
  border: 1px solid #ccc;
  border-right-width: 5px;
  border-right-color: #3c6809;
}
.boxPercentual{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 25px;
  border: 1px solid #ccc;
  border-right-width: 5px;
  border-right-color: #3c6809;
}

.carbohydrates {
  background-color: #f1e15b;
}
.proteins{
 background-color: #e8c1a0;
}
.lipids{
  background-color: #e8a838;
}
.saturated{
  background-color: #df8662;
}

.boxValue{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 25px;
  border: 1px solid #ccc;
  border-right-width: 5px;
  border-right-color: #3c6809;
}

.tacoFoodColumn3{
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 250px;
  justify-content: space-around;
}
.tacoFoodColumn3 .row {
  display: flex;
  align-items: space-between;
  margin-bottom: 10px;
}

.tacoFoodColumn3 .col1, .tacoFoodColumn3 .col2 {
  flex: 1;
}

.tacoFoodActions {
  margin-top: 20px;
  text-align: center;
}

/* Accordion */

.accordion {
  width: 95%;
  border-radius: 10px!important;
  background-color: #f1f1f1;
  margin-top: 30px;
  padding: 0px 0px 0px 0px;
}
.accordion-header {
  display: flex;
  flex-direction: row!important;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #8bc540;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  border: none;
  outline: none;
  transition: 0.4s;
  border-radius: 10px!important;
  padding: 0px 20px 0px 15px;
}

.accordion-header:hover {
  background-color: #8bc54061;
  color: #3c6809;
}
.accordion-header span{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3c6809;
  margin-top: 20px;
}
.accordion .span-div {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #3c6809;
}
.accordion-content{
  padding: 10px 12px 10px 12px;
}

.accordion .boxLineContainer {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha se necessário */
  justify-content: space-between; /* Distribui o espaço entre os itens */
  gap: 10px; /* Espaço entre os elementos */
  padding: 10px;
  font-size: 0.7rem;
}
