.navbar{
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #eeeeee;
  background-color: #8bc540;
}
.navbarBrand{
  width: 10%;
  height: 50px;
  display: flex;
  padding: 1px;
  justify-content: center;
  align-items: center;
  /* background-color: #78517e63; */
}
.imgLogo{
  width: 80px;
  height:40px;
  padding: 5px;
  margin-left: 100px;
  /* border-radius: 100%; */
  /* background-color: #5b960fb6; */
}
.navbarCenter{
  width: 70%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
  /* background-color: aquamarine; */
}
.navbarRight{
  width: 20%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userInitials {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.userInitials::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
  top: 135%; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #5b960fb6;
  opacity: 0;
  transition: opacity 0.6s;
}

.userInitials:hover::after {
  visibility: visible;
  opacity: 1;
}

.initials{
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color:aliceblue;
  background-color: #5b960fb6;
}

.user-email, .roles{
  color: aliceblue;
  /* visibility: hidden; */
}

a{
  font-size: 0.9rem;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ajusta conforme necessário */
}

.btnLogout{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100px;
  height: 25px;
  border: none;
  border-radius: 10px;
  color:aliceblue;
  background-color: #5b960fb6;
}
.btnLogout:hover{
  border: 2px solid aliceblue;
  color: #35353598;
}

#mobileMenu {
  visibility: hidden;
}
