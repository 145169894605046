.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Adicione um fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11000; /* Ajuste conforme necessário */
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: auto!important;
  max-width: 600px; /* Ajuste conforme necessário */
  max-height: 80vh; /* Ajuste conforme necessário */
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(15, 151, 2, 0.2);
  color: aliceblue;
  background-color: #8bc540;
}

.modalButtons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.modalBtn{
  width: 100px;
  height: 30px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50px;
  border: none;
  color: aliceblue;
  background-color:  #2c550a98;
}
.modalBtn:hover{
  font-weight: bold;
  color:  #2c550a98;
  border: 1px solid  #2c550a98;
  background-color: #b3ca94;
}