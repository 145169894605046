.confirmed_email{
  width: 100vw !important;
  height: 100vh !important;
  background-image: url('https://api.estima.nutricaoapp.com.br/uploads/foods/default_food.png');
  background-size: cover;
  background-position: center;
  object-fit: cover;
  opacity: 0.2;
}

.email-body {
  width: 600px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}
/* Cabeçalho */
.email-header {
  background: url('https://api.estima.nutricaoapp.com.br/uploads/avatar1738019089382_fruitLogo.png') no-repeat center center;
  background-size: cover;
  height: 150px;
  width: 50%;
}

.header-text {
  color: white;
  font-family: Arial, sans-serif;
}


.confirmation-button {
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: white;
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 5px;
  background-color: #4CAF50;
}

/* Rodapé */
.email-footer {
  text-align: center;
  padding: 10px;
  background-color: #eeeeee;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #666;
}
.email-body .exitBtn{
  width: 120px;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #e83b1dd3;
  color: white;
  border: none;
  border-radius: 5px;
}