.userForm {
  width: 50%;
  margin: auto;
  max-width: 90%;
  max-height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  /* background: linear-gradient(to top, #e5e8ebe7, #b4c3cc); */
}

.user-form-group {
  width: 80%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-form-group label {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: normal;
  align-self: flex-start;
}

.user-form-group input {
  width: 100%;
  border-radius: 5px;
  outline: none;
  text-align: center;
  color: #505050;
  padding: 3px 10px 3px 10px;
  border: 1px solid #8bc540;
  text-align: left;
}
.user-form-group select {
  width: 105%;
  border-radius: 5px;
  outline: none;
  text-align: center;
  color: #505050;
  padding: 3px 10px 3px 10px;
  border: 1px solid #8bc540;
  text-align: left;
}
.user-form-group input:focus {
  background-color: #EBF0FE !important;
}
.user-form-group input.error {
  border-color: red;
}

.userForm .error-message {
  width: 200px !important;
  padding: 5px 0px !important;
  margin-bottom: 5px;
  border-radius: 5px !important;
  text-align: center;
  font-size: 0.8rem;
  color: aliceblue !important;
  background-color: #5b960fb6 !important;
}

.userForm .spanMessage {
  width: 250px auto;
  max-width: none;
  height: 15px;
  padding: 3px;
  font-size: 0.7rem;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  margin-bottom: 3px;
  color: aliceblue;  
  background-color: #8bc540;
}

.userForm .userButtons{
  display: flex;
  width: 100%;
  gap: 25px;
  justify-content: center;
}
.sendBtn {
  width: 100px;
  height: 30px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50px;
  border: none;
  color: aliceblue;
  background-color: #5b960fb6 !important;
}
.sendBtn:hover{
  font-weight: bold;
  border: 1px solid #65327598;
  color: #5b960fb6 !important;
  background-color: #5b960f23 !important;
  cursor: pointer;
}
.getUserTable .tdBtn {
  font-size: 0.8rem;
  text-align: left;
  font-weight: normal;
  color: #115208;
}
.getUserTable .tdBtn:hover {
  font-size: 0.82rem !important;
  text-align: left;
  font-weight: normal !important;
  color: #115208;
  cursor: pointer;
}