.boxGetPerson{
  max-width: 95%;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getPersonTable {
  min-width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-size: 0.7rem;
  color: #3c6809;
  /* background-color: rgba(255, 255, 255, 0.616); */
}
.getPersonTable thead tr{
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #8bc54095;
}
.getPersonTable tbody tr{
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.616);
  margin-top: 3px;
  }
.getPersonTable tbody tr:hover{
    background-color: rgb(255, 255, 255);
}
  
.getPersonTable tr, td {
  padding: 3px;
  color: #115208;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
}
.getPersonTable button {
  font-size: 0.7rem;
  color: #115208;
}
.getPersonTable td:nth-child(1), .getPersonTable th:nth-child(1) {
  min-width: 5%;
  max-width: 5%;
}
.getPersonTable td:nth-child(2), .getPersonTable th:nth-child(2) {
  min-width:20%;
  max-width:20%;
}
.getPersonTable td:nth-child(3), .getPersonTable th:nth-child(3) {
  min-width:10%;
  max-width:10%;
}
.getPersonTable td:nth-child(4), .getPersonTable th:nth-child(4) {
  min-width:10%;
  max-width:10%;
}
.getPersonTable td:nth-child(5), .getPersonTable th:nth-child(5) {
  min-width:10%;
  max-width:10%;
}
.getPersonTable td:nth-child(6), .getPersonTable th:nth-child(6) {
  min-width:10%;
  max-width:10%;
}
.getPersonTable td:nth-child(7), .getPersonTable th:nth-child(7) {
  min-width: 6%;
  max-width: 6%;
}
.getPersonTable button.icoBtn {
  width: 20px;
  height: 20px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  text-align: center;
  padding: 2px;
  background-color: #8bc540c7;
} 

.getPersonTable button.icoBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getPersonTable button.icoBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getPersonTable button.tdBtn{
  width: 220px;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
  text-align: left !important;
}
.getPersonTable button.tdBtn:hover{
 font-weight: normal;
 
}
.getPersonTable button.tdBtn img{
  width: 30px!important;
  height: 30px!important;
  border: 2px solid #8bc540;
  border-radius: 90px;
  background-color: transparent !important;
  cursor: pointer;
}

.getPersonTable button.tdBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 160px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getPersonTable button.tdBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getPersonTable .pagination button {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border: none;
  border-radius: 2px !important;
  margin-left: 5px;
  text-align: center;
  padding: 0px;
  color: aliceblue;
  background-color: #8bc54062;
}

.getPersonTable .pagination button.active {
  color: aliceblue;
  background-color: #8bc540c7;
  padding: 0 10px 0 10px;
}
.paginationSeparator{
  color:#365c05;
}
.countLines{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 30px;
  font-size: 0.8rem;
}
.getPersonTable .searchBtn{
  color: aliceblue;
  background-color: #5b960fb6;
}

.getPersonTable .searchBtn:hover{
  color: #5b960fb6;
  background-color: #5b960f23;
  cursor: pointer;
}

.getPersonTable .rowMessage {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bolder;
  width: 100%;
  color: #115208;
  /* background-color: #65327542; */
}

@media (max-width: 768px) {
  .getPersonTable  table {
    width: 100%;
  }

  .getPersonTable th, td {
    font-size: 0.6rem;
  }
}
@media (max-width: 430px) {
  .getPersonTable table {
    width: 100%;
  }

  .getPersonTable th, td {
    font-size: 10px;
  }
  .getPersonTable button.icoBtn{
    width: 20px;
    height: 20px;
    border-radius: 1px;
    border: none !important;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-top: 5px;
    color: aliceblue;
    background-color: #5b960fb6;
  } 



}