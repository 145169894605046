.boxGetConsultation {
  max-width: 95%;
}
.getConsultationTable {
  min-width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
  font-size: 0.7rem;
  color: #3c6809;
  /* background-color: rgba(255, 255, 255, 0.616); */
}

.mobileIcon{
  display: none;
}
.getConsultationTable thead tr {
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #8bc54095; 
}
.getConsultationTable tbody tr {
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.616);
  margin-top: 3px;
}
.getConsultationTable tbody tr:hover{
  background-color: rgb(255, 255, 255);
}

.getConsultationTable tbody tr.cancelled-line {
  background-color: rgba(8, 7, 7, 0.279);
  color: white;
}
.getConsultationTable tbody tr.changed-line {
  background-color: rgba(169, 182, 240, 0.404);
  color: white;
}
.getConsultationTable tbody tr.in_progress-line {
  background-color: rgba(155, 200, 236, 0.822);
  color: white;
}
.getConsultationTable tbody tr.confirmed-line {
  background-color: rgba(236, 228, 155, 0.822);
  color: white;
}
.getConsultationTable tbody tr.completed-line {
  background-color: rgba(119, 243, 148, 0.505);
  color: white;
  /* display: none; */
}

.getConsultationTable tr, .getConsultationTable td {
  padding: 3px;
  /* border-top: 1px solid #8bc540; */
  text-align: left;
  color: #115208;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.getConsultationTable tbody tr td{
  color: #115208;
}
.getConsultationTable td:nth-child(1), .getConsultationTable th:nth-child(1) {
  min-width: 15%; /* Largura mínima para a coluna ID */
  max-width: 15%;
}

.getConsultationTable td:nth-child(2), .getConsultationTable th:nth-child(2) {
  min-width: 10%; /* Largura mínima para a coluna Clínica */
  max-width: 10%;
}

.getConsultationTable td:nth-child(3), .getConsultationTable th:nth-child(3) {
  min-width: 15%; /* Largura mínima para a coluna Paciente */
  max-width: 15%;
}

.getConsultationTable td:nth-child(4), .getConsultationTable th:nth-child(4) {
  min-width: 10%; /* Largura mínima para a coluna Profissional */
  max-width: 10%;
}

.getConsultationTable td:nth-child(5), .getConsultationTable th:nth-child(5) {
  min-width: 10%; /* Largura mínima para a coluna Data da Consulta */
  max-width: 10%;
}

.getConsultationTable td:nth-child(6), .getConsultationTable th:nth-child(6) {
  min-width: 10%; /* Largura mínima para a coluna Hora da Consulta */
  max-width: 10%;
}

.getConsultationTable td:nth-child(7), .getConsultationTable th:nth-child(7) {
  min-width: 10%; /* Largura mínima para a coluna Status */
  max-width: 10%;
}
.getConsultationTable td:nth-child(8), .getConsultationTable th:nth-child(8) {
  min-width: 10%; /* Largura mínima para a coluna Status */
  max-width: 10%;
}

.getConsultationTable button.icoBtn{
  width: 20px;
  height: 20px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  text-align: center;
  padding: 2px;
  background-color: #8bc540c7;
} 

.getConsultationTable button.icoBtn::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getConsultationTable button.icoBtn:hover::after {
  visibility: visible;
  opacity: 1;
}

.getConsultationTable a {
  cursor: pointer;
  font-size: 0.7rem;
  color: #115208;
}
.getConsultationTable a:hover{
 font-size: 0.7rem;
 color: #115208;
 font-weight: normal;
font-weight: 500;
}
.getConsultationTable button.tdBtn img{
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: transparent !important;
  cursor: pointer;
}

.getConsultationTable button.tdBtn::after, .getConsultationTable a::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 160px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.getConsultationTable button.tdBtn:hover::after, .getConsultationTable a:hover::after {
  visibility: visible;
  opacity: 1;
}

.getConsultationTable .pagination button {
  width: 20px;
  height: 20px;
  margin-top: 15px;
  border: none;
  border-radius: 2px !important;
  margin-left: 5px;
  text-align: center;
  padding: 0px;
  color: aliceblue;
  background-color: #8bc54062;
}

.getConsultationTable .pagination button.active {
  color: aliceblue;
  background-color: #8bc540c7;
}

.getConsultationTable .searchBtn{
  color: aliceblue;
  background-color: #5b960fb6;
}

.getConsultationTable .searchBtn:hover{
  color: #5b960fb6;
  background-color: #2c460b62;
  cursor: pointer;
}

.getConsultationTable .rowMessage {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bolder;
  width: 100%;
  color: #115208;
  /* background-color: #65327542; */
}
.colLine{
  display: flex;
  width: 100%;
  height: 40px;
  font-size: 0.9rem;
  font-weight: bolder;
  color: #115208;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.showActionBtn-disable{
  display: none;
}
