.personAvatar {
  display: flex;
  width: 100px;
  height: 100px;
  /* background-color: #faf86d; */
}

.silhuette { 
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8bc540;
  padding: 2px;
  z-index: 1;
    /* background-color: purple;   */
}
.silhuette div:nth-child(1){
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
.silhuette div:nth-child(2){
  padding: 0%;
  /* background: #cdff06; */
}

input[type="file"]{
 display: none;
}

label.photo{
     padding: 0%;
   /* background-color: red; */
}

img.cadphoto{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* border: 1px solid #EE0909; */
  object-fit: cover;
 }

 img.photo{
   width: 15px;
   height: 15px;
 }
 
.btnphoto{
  width: 40px;
  height: 40px;
   margin-top: 2%;
   display: block;
   padding: 0px;

   background-repeat: no-repeat;
   background-color: none;    
}
.cadphotoTimeline{
  width: 85px;
  height: 85px;
  border-radius: 50%;
}
.timeLineG{
   font-size: 0.9rem;
}
.timeLineM{
  font-size: 0.8rem;
}