.boxShowCompany {
  line-height: 20px;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  padding: 10px;
  font-weight: normal;
  background-color: rgba(255, 255, 255, 0.616);
}

.boxShowCompany h3 {
  margin: 2px 0px 0px 2px;
  font-size: 0.9rem;
}

.boxShowCompany .boxTop {
  /* background-color: rgb(22, 70, 54); */
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  gap: 10px;
}

.boxShowCompany label {
  margin-right: 6px;
}
.boxShowCompany span {
  text-decoration: none;
  font-weight: normal;
  font-size: 0.8rem;
  color: #3c6809;
}
.boxShowCompany a, .boxCol1 a {
  text-decoration: none;
  font-weight: normal;
  font-size: 0.8rem;
  cursor: pointer;
  color: #3c6809;
}

.boxShowCompany .box-line {
  /* background-color: #f7fa59; */
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
  width: 100%;
}

.boxShowCompany .boxCol1 {
  /* background-color: blue; */
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: auto;
  margin: 0 !important;
  padding: 0px 20px 0px 1px !important;
  line-height: 3px;
}
.boxCol1 {
  display: flex !important;
  flex-wrap: wrap;
  line-height: 15px !important;
}
.box-line{
  display: flex !important;
  flex-wrap: wrap;
}
.boxAppointment {
  width: 150px auto;
  height: 150px auto;
  font-size: 0.7rem;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}