/* Resetando todos os estilos do React Select usando o prefixo */
.custom-select__control,
.custom-select__value-container,
.custom-select__input,
.custom-select__single-value,
.custom-select__placeholder,
.custom-select__indicators,
.custom-select__indicator,
.custom-select__dropdown-indicator,
.custom-select__clear-indicator,
.custom-select__menu,
.custom-select__menu-list,
.custom-select__option {
  all: unset !important; /* Remove todas as propriedades de estilo padrão */
  box-sizing: border-box !important; /* Mantém o comportamento esperado de box-sizing */
}

/* Aplicando seus estilos personalizados */
.custom-select__control {
  background-color: #fcfcfc!important;
  border: 1px solid #8bc540!important;
  min-height: 10px!important;
  display: flex!important;
  justify-content: space-between!important;
  align-items: center!important;
  transition: border-color 0.2s ease!important;
  padding: 0px 10px 0px 10px !important;
  border-radius: 50px!important;
  font-size: 0.8rem!important;
}

.custom-select__control--is-focused {
  /* border-color: white !important; */
  background-color: white !important;
  box-shadow: 0 0 0 1px #5A5A5A !important;
}

.custom-select__control--is-focused .css-1jqq78o-placeholder{
    /* display: none !important; */
}

.custom-select__control:hover {
  border: 1px solid #8bc540;
  z-index: 1 !important;
}

.custom-select__input {
  padding: 0px 8px!important;

}

.custom-select__input-container {
  color: #8bc540!important;
  z-index: 1;
}
/* Adicionando o estilo do pseudo-elemento ::after ao contexto */
.custom-select__input-container::after {
  visibility: visible!important;
  color: #3c6809;
}

.custom-select__single-value {
  color: #3c6809 !important;
}

.custom-select__indicator-separator {
  display: none;
}

.custom-select__dropdown-indicator {
  color: #3c6809!important;
  padding: 8px;
  font-size:  0.8rem!important;
}

.custom-select__dropdown-indicator:hover {
  color: #3c6809!important;
}

.custom-select__menu {
  width: 300px !important;
  min-height: 300px !important;
  height: auto !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  z-index: 1000 !important;
  position: absolute !important;
  /* background-color: #d7fda6!important; */
  background-color: white !important;
}
.custom-select__menu-list {
display: flex !important;
flex-direction: column!important;
}
.custom-select__option {
  padding: 8px 12px !important;
  cursor: pointer !important;
}

.custom-select__option--is-focused {
  color: white !important;
  background-color: #8bc540 !important;
}

.custom-select__option--is-selected {
/* background-color: red !important; */
}


/* .................................................................... */

