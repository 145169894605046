.login-container{
  width: 98%;
  min-height: 70vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0px auto;
  margin-top: 40px;
  border-radius: 5px;
  background-color: #8bc5404d !important;
}

span{
  margin: 10px 0px 20px 0px;
  color: aliceblue;
  font-size: 1rem;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
}

.loginBox{
  margin: 0px auto;
  width: 230px;
  min-height: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: 3px solid #69ff8e54;
  background-color: #8bc54018 !important;
}

.loginBox .form-label{
  width: 100%;
  text-align: left;
  color: rgb(235, 228, 226);
}
.loginBox input{
  width: 200px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  border: 1px solid #d9d8d854;
}
input#cbForgot{
  width: 15px;
  border: 1px solid #d9d8d854;
  margin-top: 25px;
}
.loginBtn {
  display: flex;
  justify-content:center ;
}

.btn {
  width: 120px;
  height: 40px;
  color: aliceblue;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  border: 3px solid #b7b9b6de;
  background-color: #5b960fb6 !important;
}
.btn:hover{
  border: 3px solid #245e08de;
  color: #8bc540;
  font-weight: bold;
  background-color: rgba(240, 248, 255, 0.671) !important;
}
.title{
  width: 30%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

img{
  width: 30px;
  height: 30px;
}

.loginFallbackImg{
  width: 100%;
  height: auto !important;
}
.message{
  font-weight: normal;
  color: rgb(229, 235, 226);
  text-align: center;
  margin-bottom: 20px;
}
.exitLink{
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  a {
    font-size: 1rem;
    font-weight: bold;
    color: rgb(201, 174, 217);
  }
}

.sign {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -30px;
  margin-left: 35px;
  font-size: 0.7rem;
  font-weight: bold;
  color: aliceblue;
}
.sign:hover {
    font-size: 0.9rem;
      font-weight: bold;
      color: aliceblue !important;
}

.loginVideoBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.loginVideoBox video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
