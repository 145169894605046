.boxShowUser {
  max-width: 50vw !important;
  padding: 30px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.boxShowUser h3 {
  font-size: 0.9rem;
}
.boxShowUser .box-line{
  display: flex;
  padding: 5px;
}
.boxShowUser .boxCol1{
  align-items: baseline;
}
.boxShowUser .boxCol1 label {
  margin-right: 15px;
  color: #010101;
}
.boxShowUser .boxCol1 span{
  color: #3c6809;
}
.form-group-show {
  width: 90%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group-show label {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: normal;
  align-self: flex-start;
}

.form-group-show input {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.form-group-show input:focus {
  background-color: #EBF0FE !important;
}
.form-group-show input.error {
  border-color: red;
}
.box-group-show {
  width: 80%;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-line{
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 12px;
}

.error {
  color: red !important;
}
.spanMessage {
  width: 200px;
  height: 15px;
  padding: 3px;
  font-size: 0.7rem;
  border-radius: 5px;
  color: aliceblue;
  align-self: center;
  text-align: center;
  margin-bottom: 3px;
  background-color: #5b960fb6;
}

.spanData{
  color: #653275;
  font-size: 14px;
}

.userButtons{
  display: flex;
  width: 100%;
  border-top: 1px solid #653275;
  justify-content: space-around;
  margin-top: 30px;
  padding-top: 10px;
}
.sendBtn {
  width: 100px;
  height: 30px;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 50px;
  border: none;
  color: aliceblue;
  background-color: #65327598;
}
.sendBtn:hover{
  font-weight: bold;
  color: #65327598;
  border: 1px solid #65327598;
  background-color: #c9aed9;
}
.delete .icoBtn{
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 2px;
  margin: 2px;
}