.boxShowConsultation {
  max-width: 75vw !important;
  padding: 30px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  padding: 5px 40px 20px 40px;
}
.boxShowConsultation .box-line{
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.boxShowConsultation h3 {
  font-size: 0.9rem;
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}
.boxShowConsultation .box-line{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.boxShowConsultation .boxCol1 {
  align-items: baseline;
  min-width: 20%;
  width: auto !important;
  width: auto !important;
  /* border: 1px solid #084948; */
  padding: 6px !important;
  margin: 0px !important;
  border-radius: 5px;
}
.boxShowConsultation .boxCol1 label {
  margin-right: 15px;
  color: #010101;
}
.boxShowConsultation .boxCol1 span{
  color: #3c6809;
  /* padding: 10px 5px 10px 5px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 5px; */
}
.boxShowConsultation .boxCol1 .spanData{
  color: #3c6809;
  margin: 0px !important;
  /* background: radial-gradient(circle, rgba(245, 245, 245, 0) 0%, #f5f5f5 100%); */
}
.boxShowConsultation .lineBoxTop{
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.consultation-form-group ul.anthropometry-list .anthropometry-item {
  /* background-color: red; */
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
.consultation-form-group li.anthropometry-item .icon {
  /* color: yellow; */
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 10px;
}
.consultation-form-group li.anthropometry-item span {
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
}
.boxShowConsultation .btnNewConsult {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.boxShowConsultation .sendNewConsultBtn {
  width: 350px;
  height: 35px;
  border-radius: 30px;
  background-color: #5b960fb6;
  color: aliceblue;
}
.boxShowConsultation .sendBtnSections {
  min-width: 150px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  background-color: #5b960fb6;
  color: aliceblue;
  cursor: pointer;
}

.boxShowConsultation .sendNewConsultBtn:hover {
  cursor: pointer;
  background-color: aliceblue;
  color: #5b960fb6;
  border: 2px solid #5b960fb6;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}


.boxShowConsultation .boxButtons1,.boxShowConsultation .boxButtons2, .boxShowConsultation .boxButtons3, .boxShowConsultation .boxButtons4, .boxShowConsultation .boxButtons5 {
  display: flex;
  flex-direction: column;
  position: fixed; /* Fixa o painel na lateral da tela */
  right: 0; /* Alinha à direita */
  top: 40%; /* Posiciona no meio verticalmente */
  transform: translateY(-50%); /* Ajusta o posicionamento para que o meio dos botões fique no centro vertical */
}
.boxShowConsultation .boxButtons2{
  margin-top: 40px;
}
.boxShowConsultation .boxButtons3{
  margin-top: 80px;
}
.boxShowConsultation .boxButtons4{
  margin-top: 120px;
}
.boxShowConsultation .boxButtons5{
  margin-top: 160px;
}

.boxShowConsultation .showBoxBtn {
  background-color: #8bc54069;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  border: none;
  border-radius: 5px;
  color: aliceblue;
  width: 60px; /* Largura inicial para mostrar apenas ícones */
  overflow: hidden; /* Esconde o texto inicialmente */
  white-space: nowrap; /* Mantém o texto na mesma linha */
  transition: width 0.3s ease; /* Suaviza a transição */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Impede o botão de encolher */
}

.boxShowConsultation .showBoxBtn:hover {
  width: 120px; /* Largura maior ao passar o mouse para mostrar o texto */
  cursor: pointer;
}

.boxShowConsultation .showBoxBtn .sideBtnSpan {
  margin-left: 10px; /* Espaçamento entre ícone e texto */
  font-size: 0.8rem; /* Tamanho do texto */
  display: none; /* Esconde o texto até hover */
}

.boxShowConsultation .showBoxBtn:hover .sideBtnSpan {
  display: inline; /* Mostra o texto no hover */
  color: #365c05;
}

.boxShowConsultation .showBoxBtn-disable {
  background-color: #23311056;
  box-shadow: 0 4px 8px rgba(1, 27, 61, 0.687);
  border: none;
  border-radius: 5px;
  color: aliceblue;
  width: 60px; /* Largura inicial para mostrar apenas ícones */
  overflow: hidden; /* Esconde o texto inicialmente */
  white-space: nowrap; /* Mantém o texto na mesma linha */
  transition: width 0.3s ease; /* Suaviza a transição */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Impede o botão de encolher */
}

.boxShowConsultation .showBoxBtn-disable:hover {
  width: 35px; /* Largura maior ao passar o mouse para mostrar o texto */
  cursor: pointer;
}

.boxShowConsultation .showBoxBtn-disable .sideBtnSpan {
  margin-left: 10px; /* Espaçamento entre ícone e texto */
  font-size: 0.8rem; /* Tamanho do texto */
  display: none; /* Esconde o texto até hover */
}

.boxShowConsultation .showBoxBtn-disable:hover .sideBtnSpan {
  display: inline; /* Mostra o texto no hover */
  color: #365c05;
}

.boxShowConsultation .sideIcoBtn {
  font-size: 20px; /* Tamanho do ícone */
  color: #365c05;
}
.boxShowConsultation span a::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px auto;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.boxShowConsultation span a:hover::after {
  visibility: visible;
  opacity: 1;
}

.diet-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%; 
  height: auto;
  font-weight: lighter;
  color: #3c6809;
  /* background-color: red; */
}
.cardMealsTop{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 10px;
  color: #365c05;
}
.cardMealsTop .icon{
text-align: center;
width: 140px;
height: 140px;
position: absolute;
z-index: 10!important;
opacity: calc(0.4);
}
.diet-colA{
  width: 100%;
  margin: 0px 0px 10px 0px;
}

.dietCardA{
  margin-bottom: 0px;
  padding: 10px 0px 10px 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 10px;
  border: 1px solid #ccc;
  /* background-color: blue; */
}
.line-colA{
  display: flex;
  flex-direction: column;
}
.diet-colB{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* background-color: pink; */
}
.dietCardB{
  width: 30%;
  padding: 10px 0px 10px 5px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
}

.line-colB{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0px 0px 0px;
}
.dietCardA .spanData{
  color: #3c6809;
  font-size: 0.8rem;
  font-weight: lighter;
}
.dietCardB .spanDataTop{
  color: #365c05;
  font-size: 0.8rem;
  font-weight: bold;
}
.dietCardB .spanData{
  padding: 0px;
  color: #3c6809;
  font-size: 0.8rem;
  font-weight: lighter;
  width: 200px;
}
.dietCardB .spanDataLine{
  width: 31%;
  padding: 0px;
  color: #3c6809;
  font-size: 0.8rem;
  font-weight: lighter;
}


.box-diet{
  /* background-color: aqua; */
  font-size: 0.8rem;
}
.box-diet-col-A{
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px 5px 10px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 20px;
  /* background-color: blue; */
}
.box-diet-col-B{
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
 width: 100%;
}
.meal-card{
  width: 45%;
  border: 1px solid #365c0520 !important;
  border-radius: 5px;
  margin-bottom: 10px;
}
.meal-line{
display: flex;
flex-wrap: wrap;
gap: 10px;
align-items: center;
box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
background-color: rgba(255, 255, 255, 0.616);
border-radius: 5px;
border: 1px solid #ccc;
}
.label-card{
display: flex;
justify-content: center;
align-items: center;
width: 30px; 
height:30px;
box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
/* background-color: rgba(255, 255, 255, 0.616); */
border-radius: 5px;
border: 1px solid #ccc;
}
.meal-title{
  color: #3c6809;
  font-size: 0.8rem;
}

.label-icon{
  font-size: 18px;
  color: #3c6809;
}


.spanDataTitle{
  color: #365c05;
  font-size: 0.8rem;
}
.spanDataProcedures{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.box-diet .meal-card{
  padding: 0px 2px 0px 2px;
  /* background-color: red; */
  border: 1px solid #3c6809;
}

.dietFoodLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f9f9f9;
  background: #d1f0cec4;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  margin: 0px 2px 10px 2px;
}

.dietFoodLine li {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding: 0.1rem;
  margin: 0px;
}
.dietFoodLine .liCol {
  /* flex: 1;  */
}


.dietFoodLine .liCalories {
  margin-left: auto;
  text-align: right;
}
.consultationGlobal .modal-content {
  min-width: 500px !important;
}
.consultationGlobal .modal-content p {
   text-align: center;
   line-height: 40px;
 }
.consultationGlobal .spanDataChecked{
  border-radius: 50%;
  background: #3c6809;
  color: aliceblue;
  font-size: 1rem;
  padding: 3px 5px 3px 5px;
}
.consultationGlobal .message-line{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.spinnerBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3c6809;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.spinner_mini {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid #3c6809;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
