.boxShowAppointment {
  max-width: 50vw !important;
  padding: 30px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  line-height: 0.5px;
  padding: 5px 5px 0px 40px;
}
.boxShowAppointment h3 {
  font-size: 0.9rem;
}
.boxShowAppointment .box-line{
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.boxShowAppointment .boxCol1{
  align-items: baseline;
}
.boxShowAppointment .boxCol1 label {
  margin-right: 15px;
  color: #010101;
}
.boxShowAppointment .boxCol1 span{
  color: #3c6809;
  padding: 10px 5px 10px 5px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 5px;
}

.btnNewConsult{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.sendNewConsultBtn {
  width: 350px;
  height: 35px;
  border-radius: 30px;
  background-color: #5b960fb6;
  color: aliceblue;
}

.sendNewConsultBtn:hover{
  cursor: pointer;
  background-color: aliceblue;
  color: #5b960fb6;
  border: 2px solid #5b960fb6;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}
.boxShowAppointment .modal-content p{
  text-align: center;
  line-height: 40px;
  min-width: 350px !important;
}
.boxShowAppointment .modal-content {
  min-width: 350px!important;
}
.boxShowAppointment .showActionBtn-disable { 
  display: none;
}
.boxShowAppointment .spanDataChecked{
  border-radius: 50%;
  background: #3c6809;
  color: aliceblue;
  font-size: 1rem;
  padding: 3px 5px 3px 5px;
}
.boxShowAppointment .spanDataTop{
  color: #3c6809;
  font-weight: normal;
}
.boxShowAppointment .message-line{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.spinnerBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3c6809;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.spinner_mini {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid #3c6809;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
