.consultationForm{
  max-width: 90vw !important;
  padding: 20px;
  border: 1px solid #8bc540;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
}

.consultationMain {
  margin: auto;
  width: 90%;
  gap: 5px;
  margin-top: 20px;
  /* background-color: blue; */
}
.consultationLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}
.consultation-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* background-color: aqua; */
}

.consultation-form-group label {
  padding-bottom: 2px;
  color: #3c6809;
}

.consultation-form-group input {
  width: 50%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}

.consultation-form-group select, .consultation-form-group .searchInputDropdown {
  width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  color: #3c6809;
  border: 1px solid #8bc540;
}


.dropdown-list{
  min-width: 300px;
  width: auto !important;
  height: auto;
  padding: 10px 30px 20px 30px;
  margin-top: 2px;
  /* position: absolute; */
  border-radius: 30px;
  color: #3c6809;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  /* display: none; */
}
.consultation-form-group textarea {
  max-width: 90%;
  border-radius: 50px;
  outline: none;
  text-align: center;
  border: 1px solid #8bc540;
  color: #505050;
}

#scheduling_date, #consultation_date, #consultation_time {
  width: 150px;
}
.consultation-form-group input:focus {}

.consultation-form-group input.error, .consultation-form-group select.error {
  border: 1px solid red;
}

.consultation-form-group #birth_date, .consultation-form-group #since_date {
  width: 110px;
  color: #505050;
}

.trSelects {
  display: flex;
  gap: 30px;
  padding: 5px;
  /* background-color: purple; */
}

.error-message {
  color: red;
}

.spanMessage {
  max-width: 160px;
}

.formButtons{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.consultationButtons .sendBtn {
  width: auto;
  height: 25px;
  margin-top: 25px;
  padding: 0px 12px 0px 12px;
}
.consultationButtons .sendBtnLarge{
  width: 22%;
  min-height: 30px;
  height: auto;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  color: aliceblue;
  font-size: 100%;
  background-color: #5b960fb6;
}
.consultationButtons{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.btnIcoAddRm.iconPlus {
  width: 20px;
  height: 20px;
  border: none !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  color: aliceblue;
  text-align: center;
  padding: 2px;
  background-color: #406fc5c7;
} 

.btnIcoAddRm.iconPlus::after {
  content: attr(data-tip);
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: fixed;
  z-index: 2;
  top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
  left: 50%;
  margin-left: -60px;
  font-size: 0.8rem;
  color:aliceblue;
  background-color: #8bc540c7;
  opacity: 0;
  transition: opacity 0.6s;
}

.btnIcoAddRm.iconPlus:hover::after {
  visibility: visible;
  opacity: 1;
}

.anthropometryButtons {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.anthropometryButtons .sendBtn {
  width: auto;
  min-height: 25px;
  height: auto;
  padding: 0px 12px 0px 12px;
  font-size: 100%;
}
.anthropometry-list {
  display: flex;
  flex-wrap: wrap; /* Permite itens quebrarem para a próxima linha, se necessário */
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 0.5rem; /* Espaçamento entre itens */
}

.anthropometry-item {
  display: flex;
  align-items: baseline;
  gap: 0.5rem; /* Espaço entre o ícone e o texto */
  background: #f9f9f9; /* Fundo leve para destacar */
  border-radius: 8px;
  padding: 0.5rem; /* Espaçamento interno */
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
}

.anthropometry-item .icon {
  font-size: 1rem;
  color: #3c6809; /* Cor dos ícones */
}

.anthropometry-item span {
  font-size: 0.8rem;
  font-weight: 500; /* Deixa o texto mais evidente */
  color:#3c6809;
}

.btnIcoAddRm {
  background-color: transparent !important;
  border: none !important;
  color: #3c6809;
  cursor: pointer;
}

.box-diet .meal-card{
  padding: 0px 2px 0px 2px;
  /* background-color: red; */
  border: 1px solid #3c6809;
}

.dietFoodLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f9f9f9;
  background: #d1f0cec4;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  margin: 0px 2px 10px 2px;
}

.dietFoodLine li {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding: 0.1rem;
  margin: 0px;
}
.dietFoodLine .liCol {
  /* flex: 1;  */
}


.dietFoodLine .liCalories {
  margin-left: auto;
  text-align: right;
}
.consultationForm .box-line{
  display: flex;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  
}
.consultationForm .box-line .boxCol1{
  gap:15px;
}

.consultationForm .boxCol1 span{
  color: #3c6809;
  padding: 5px 15px 5px 15px;
  box-shadow: 0 4px 8px rgba(51, 145, 7, 0.687);
  background-color: rgba(255, 255, 255, 0.616);
  border-radius: 5px;
}
@media (max-width: 1150px) {}
@media (max-width: 768px) {}
@media (max-width: 430px) {}

