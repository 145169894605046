@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Architects+Daughter&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  font-family: "Architects Daughter", cursive;
  font-family: ABeeZee, "Helvetica Neue", Helvetica, Arial;
}

.main-container {
  display: grid;
    grid-template-areas:
        "navbar navbar"
        "sidebar content"
        "footer footer";
    grid-template-columns: 12% 88%; /* Sidebar toma 10% e content 90% */
    grid-template-rows: auto 1fr auto; /* Altura automática para a navbar e footer, flexível para o content */
    min-height: 100vh;
    max-width: 100%;
    position: relative; /* Isso ajuda a posicionar footer no final corretamente */
}
.navbar {
  grid-area: navbar;
  background-color: #8bc540;
  padding: 10px;
  color: white;
  position: fixed;
  width: 100%;
  height: 30px!important;
  top: 0;
  z-index: 1000; /* Assegura que a navbar fique sobre outros elementos */
}

.sidebar {
  grid-area: sidebar;
  /* background-color: #f4f4f4; */
    color: white;
    padding: 15px;
    box-sizing: border-box;
    position: sticky; /* Muda de fixed para sticky */
    top: 30px; /* Deslocado para baixo igual à altura da navbar */
    height: calc(100vh - 30px); /* Ajusta a altura do sidebar */
}

.content {
  grid-area: content;
  padding: 20px;
  /* background-color: #f4f4f4; */
  overflow: auto; /* Adiciona scroll se necessário */
  margin-top: 30px; /* Desloca o content para baixo pela altura da navbar */
}

.footer {
  grid-area: footer;
  background-color: #8bc540;
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.fallbackImgHome{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  opacity: 0.2;
  z-index: -1;
}
.fallbackFace{
  position: fixed;
  width: 100vw;
  height: 100vh;
  /* transform: translate(-50%, -50%); */
  background: linear-gradient(to right, rgb(255, 255, 255), rgba(5, 96, 20, 0.388), rgba(255, 255, 255, 0.877));
}

a{
  text-decoration: none !important;
}
