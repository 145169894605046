
.userSearchForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.userSearchInput {
    width: 220px;
    height:   15px;
    font-size: 0.8rem;
    text-align: center;
    padding: 5px;
    border: 0.5px solid #65327598;
    border-radius: 50px;
  }
  .userSearchInput:focus {
    border: 0.5px solid #65327598; /* Define a borda desejada ao focar no campo */
    outline: none; /* Remove a borda azul interna */
}
.userSearchForm .formButtons {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center !important;
}
.userSearchForm .formButtons .backBtn{
  align-self: center !important;
  margin: 0;
}

.userSearchForm .formButtons .returnDashboard {
  align-self: center !important;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin: 0;
  color: aliceblue;
  background-color: #6a9630;
  border: none !important;
}
  .searchBtn {
    width: 100px;
    height: 25px;
    font-size: 0.8rem;
    padding: 5px;
    border-radius: 50px;
    border: none;
    color: aliceblue;
    background-color: #65327598;
  }
  .searchBtn:hover{
    font-weight: bold;
    color: #65327598;
    border: 1px solid #65327598;
    background-color: #c9aed9;
  }

  .userSearchForm .formButtons button.returnDashboard::after {
    content: attr(data-tip);
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: fixed;
    z-index: 2;
    top: 105px; /* Posiciona a tooltip acima ou abaixo do texto */
    left: 50%;
    margin-left: -60px;
    font-size: 0.8rem;
    color:aliceblue;
    background-color: #8bc540c7;
    opacity: 0;
    transition: opacity 0.6s;
  }
  
  .userSearchForm .formButtons button.returnDashboard:hover::after {
    visibility: visible;
    opacity: 1;
  }
  