.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* overflow-x: hidden; */
  width: 200px !important;
  height: auto !important;
  margin-top: 60px;
  margin-bottom: 120px;
}

.sidelink {
  font-size: 0.8rem;
  margin-left: 1px;
  border-radius: 20px;
  color:#3c6809;
}

.sidelink:hover {
  font-size: 0.8rem;
  width: 65%;
  height: 20px;
  color:#3c6809;
}
.asidelogo{
  width: 30px;
  height: 30px;
}

.dropdown .dropdown-toggle {
  display: flex;
  font-size: 0.8rem;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

#dropdown-basic{ 
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  gap: 5px;
  color:#3c6809;
  background-color: transparent;
  z-index: 1001;
}

#dropdown-basic.asideLink.dropdown-toggle.btn.btn-primary{
  font-size: 0.8rem;
  height: 20px;
  width: 140px!important;
  border-radius: 20px;
  color:#3c6809;
  padding: 12px;
  background-color: transparent !important;
}
.icoDown{
  margin-left: -320px;
}

#dropdown-basic.asideLink.dropdown-toggle.btn.btn-primary:hover .icoDown {
  margin-left: 30px; 
}

.dropdown-menu{
  display: none;
  z-index: 5 !important;
}

.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  min-width: 110px;
  width: auto !important;
  height: 40px auto;
  gap: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.78rem;
  background-color: #5b960f;
  z-index: 5 !important;
}
.dropdown-menu.show span {
  width: auto !important;
  color:#3c6809;
  font-size: 0.8rem;
  font-style: italic;
}
.dropdown-menu.show a.dropdown-item{
  color: aliceblue;
  font-size: 0.7rem;
}


/* Efeito do underline do Submenu */
.dropdown-menu.show a.dropdown-item {
  position: relative;
  overflow: hidden;
}

.dropdown-menu.show a.dropdown-item::before,
.dropdown-menu.show a.dropdown-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  background: aliceblue;
  transition: width 0.6s ease;
}

.dropdown-menu.show a.dropdown-item::before {
  left: 50%;
}

.dropdown-menu.show a.dropdown-item::after {
  right: 50%;
}

.dropdown-menu.show a.dropdown-item:hover::before,
.dropdown-menu.show a.dropdown-item:hover::after {
  width: 50%;
}
/*Final Efeito do underline do Submenu */