.footerLogin{
  width: 100%;
  height: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0px;
  font-size: 0.8rem;
  color: rgb(197, 240, 172);
}
.footerLogin .brand{
  color: rgb(197, 240, 172);
  font-size: 1rem;
  font-style: italic;
  font-family: monospace;
  font-weight: bold;
}
.footerLogin .year {
  color: rgb(197, 240, 172);
  font-size: 0.8rem;
  font-family: monospace;
  font-weight: 100;
}
.footerLogin span{
  color: rgb(197, 240, 172);
  font-size: 0.8rem;
  font-family: monospace;
  font-weight: 100;
}

.forgotTitle{
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  span{
    font-size: 1rem;
    font-weight: bold;
  }
}