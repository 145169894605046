.main-food {
  width: 100%;
  padding: 0px;
}

.food-content{
  width: 90%;
  margin: auto;
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.459);
}

.food-content ul, .food-content a {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  font-weight: normal;
  font-size: 0.8rem;
  color: #3c6809;
}

.food-content li {
  margin-bottom: 10px;
  padding: 10px 0px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
}
.food-content li label {
  padding-bottom: 3px;
  color: #292929;
}
.custom-cursor-li {
  cursor: url('../../../assets/img/shrimp.png'), pointer;
  width: 10px;
  height: 10px;
}
.title-span{
  font-size: 0.7rem;
}
.food-content .rowMessage{
  color: #3c6809;
}
.row-food{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2px;

}
.row-food .col1 {
  min-width: 30%;
  display: flex;
  flex-direction: column;
}
.row-food .col2 {
  min-width: 25%;
  display: flex;
  flex-direction: column;
}
.row-food .col3, .col4, .col5, .col6{
  min-width: 10%;
  display: flex;
  flex-direction: column;
}

.row-food:hover .col1,
.row-food:hover .col2,
.row-food:hover .col3,
.row-food:hover .col4,
.row-food:hover .col5,
.row-food:hover .col6 {
    border-left: 2px solid #8bc540;
    padding-left: 2px;
}

